import { BlitzPage, Routes } from "@blitzjs/next";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { LoadingIcon } from "app/core/components/loading";
import { useCurrentUser } from "app/core/hooks/useCurrentUser";
import Layout from "app/core/layouts/Layout";

const Home: BlitzPage = () => {
  return <HomeContent />;
};

const HomeContent = () => {
  const router = useRouter();
  const user = useCurrentUser();
  const [redirected, setRedirected] = useState(false);

  const hasUserContactDetails = useMemo(() => {
    if (user == null) return false;

    return !isEmpty(user.name) && !isEmpty(user.company) && !isEmpty(user.contactRole);
  }, [user]);

  // Redirect the user to the appropriate page
  useEffect(() => {
    if (user == null) return;
    if (redirected) return;

    let next = Routes.AnalysisPage({}).pathname;

    setRedirected(true);
    void router.push(next);
  }, [user, router, redirected, hasUserContactDetails]);

  return (
    <main>
      <div
        className="flex flex-col justify-center items-center h-full"
        style={{ minHeight: 340, maxHeight: 800 }}
      >
        <LoadingIcon className="w-16 h-16" />
      </div>
    </main>
  );
};

Home.authenticate = { redirectTo: Routes.LoginPage() };
Home.suppressFirstRenderFlicker = true;
Home.getLayout = (page) => <Layout title="Kraftful">{page}</Layout>;

export default Home;
