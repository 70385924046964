import { useQuery } from "@blitzjs/rpc";
import { UserType, userTypeAnalytics, userTypeKlever } from "types";

import getCurrentUser from "app/users/queries/getCurrentUser";

import { UserSubscriptionInfo } from "../subscription-manager";

export type CurrentUser = Omit<UserSubscriptionInfo, "type"> & { type: UserType };

export const useCurrentUser = (): CurrentUser | null => {
  const [user] = useQuery(getCurrentUser, null, {
    // Disable refetching on window focus
    refetchOnWindowFocus: false,
    // Set a 10 minute staleTime to prevent so many queries
    staleTime: 10 * 60 * 1000,
    // Disable the throwing of promises while fetching
    suspense: false,
  });

  // Coalesce null/undefined into null
  if (user == null) return null;

  // Massage the user type for typescript
  let userType: UserType;
  if (user.type !== userTypeKlever && user.type !== userTypeAnalytics) {
    userType = userTypeAnalytics;
  } else {
    userType = user.type;
  }

  return {
    ...user,
    type: userType,
  };
};
