import { SessionContext, SimpleRolesIsAuthorized } from "@blitzjs/auth";
import { User } from "db";
import { z } from "zod";

// Note: You should switch to Postgres and then use a DB enum for role type
export const roleSchema = z.enum(["USER", "ADMIN", "OWNER"]);
export type Role = z.infer<typeof roleSchema>;

export const userTypeAnalytics = "analytics";
export const userTypeKlever = "klever";
export const userTypeSchema = z.union([z.literal(userTypeKlever), z.literal(userTypeAnalytics)]);
export type UserType = z.infer<typeof userTypeSchema>;

declare module "@blitzjs/auth" {
  export interface Ctx {
    session: SessionContext;
    ipAddress: string | undefined;
  }
  export interface Session {
    isAuthorized: SimpleRolesIsAuthorized<Role>;
    PublicData: {
      userId: User["id"];
      role: Role;
    };
  }
}
