export const BAR_COLOR_VALUES = {
  darkBlue: "#0046bf",
  yellow: "#edbd20",
  lightBlue: "#007ade",
  green: "#3ebd66",
  black: "#000000",
};

export const BAR_COLORS = [
  BAR_COLOR_VALUES.darkBlue,
  BAR_COLOR_VALUES.yellow,
  BAR_COLOR_VALUES.lightBlue,
  BAR_COLOR_VALUES.green,
  BAR_COLOR_VALUES.black,
];

export const COLOR_POSITIVE = "#3EBE64";
export const COLOR_NEGATIVE = "#E15451";

export const STAR_COLORS = ["#EC6852", "#EC9C52", "#EEB240", "#AFD362", "#62D37C"];
